import {
    SELECT_CATEGORY,
    SELECT_PRODUCT,
    FETCH_SETTINGS,
    FETCH_CATEGORIES,
    FETCH_PRODUCTS,
    FETCH_NEWSLETTERS,
    SIGNUP_USER, ADDED_SOMETHING, FETCH_USERS, FETCH_CAREERS
} from "../constants/ActionTypes";
import axios from 'axios';
import {getAllProducts, getAllCategories, consoleLog} from "../constants/Helper";




export const select_category = (category) => {
    return {
        type: SELECT_CATEGORY,
        payload: category
    };
};
export const select_product = (product) => {
    return {
        type: SELECT_PRODUCT,
        payload: product
    };
};
export const fetch_settings = (settings) => {
    consoleLog(settings.data,"settings");

    return {
        type: FETCH_SETTINGS,
        payload: settings
    };
};
export const fetch_careers = (careers) => {
    return {
        type: FETCH_CAREERS,
        payload: careers
    };
};
export const fetch_categories = (categories) => {

    return {
        type: FETCH_CATEGORIES,
        payload: categories
    };
};
export const fetch_products = (products) => {
    return {
        type: FETCH_PRODUCTS,
        payload: products
    };
};
export const fetch_newsletters = (newsletter) => {
    return {
        type: FETCH_NEWSLETTERS,
        payload: newsletter
    };
};
export const fetch_users = (users) => {
    return {
        type: FETCH_USERS,
        payload: users
    };
};
export const something_changed = (payload = null) => {
    return {
        type: ADDED_SOMETHING,
        payload
    };
};
