const webserver = "https://backend.karwanchigroup.com/";
// const webserver = "http://127.0.0.1:8000";

export default {
    getRestaurant: `${webserver}/api/gc`,
    login: `${webserver}/api/auth/login`,
    register: `${webserver}/api/auth/register`,
    userUpdate: `${webserver}/api/auth/userUpdate`,
    logout: `${webserver}/api/auth/logout`,


    allRestaurants:`${webserver}/api/getAllRestaurant`,
    addRestaurant:`${webserver}/api/createRestaurant`,
    deactivateRestaurant:`${webserver}/api/deleteRestaurant`,
    // getRestaurant:`${webserver}/api/getRestaurant/`,
    getSettingsInfo:`${webserver}/api/getSettings`,

    getCareers:`${webserver}/api/getCareers`,
    addCareer:`${webserver}/api/createCareer`,
    deleteCareer:`${webserver}/api/deleteCareer`,

    getProducts:`${webserver}/api/getProducts`,
    addProduct:`${webserver}/api/createProduct`,
    deleteProduct:`${webserver}/api/deleteProduct`,

    addCategory:`${webserver}/api/createCategory`,
    deleteCategory:`${webserver}/api/deleteCategory`,
    getCategories:`${webserver}/api/getCategories`,

    addNewsLetter:`${webserver}/api/createNewsLetter`,
    deleteNewsLetter:`${webserver}/api/deleteNewsLetter`,
    getNewsLetters:`${webserver}/api/getNewsLetters`,

    deleteUser:`${webserver}/api/auth/deleteUser`,
    getUsers:`${webserver}/api/getUsers`,



    getLanguages:`${webserver}/api/getLanguages`,
    getCountries:`${webserver}/api/getCountries`,
    getCities:`${webserver}/api/getCities`,

}
