import {
    something_changed,
    fetch_categories,
    fetch_products,
    fetch_careers,
    select_category,
    fetch_settings,
    select_product,
    fetch_newsletters
} from "../../actions/Company";
import {
    hideMessage,
    showAuthLoader,
    userFacebookSignIn,
    userGithubSignIn,
    userGoogleSignIn,
    userSignOut,
    userSignIn,
    userTwitterSignIn
} from "../../actions";

export function CategoriesDispatch() {
    return {
        select_category,
        something_changed,
        fetch_categories
    }
}

export function CategoriesFormDispatch() {
    return {
        something_changed,
        select_category,
        fetch_products,
        fetch_categories,
    }
}
export function UsersDispatch() {
    return {
        select_category,
        something_changed,
        fetch_categories
    }
}

export function UsersFormDispatch() {
    return {
        something_changed,
        select_category,
        fetch_products,
        fetch_categories,
    }
}

export function SignInDispatch() {
    return {
        userSignIn,
        hideMessage,
        showAuthLoader,
        userFacebookSignIn,
        userGoogleSignIn,
        userGithubSignIn,
        userTwitterSignIn
    }
}

export function AppDispatch() {
    return {
        something_changed,
        select_product,
        fetch_products,
        fetch_categories,
        fetch_newsletters,
        fetch_careers,
        userSignOut
    }
}

export function CareersDispatch() {
    return {
        select_category,
        something_changed,
        fetch_categories,
        fetch_careers,
        userSignIn
    }
}

export function CareersFormDispatch() {
    return {
        something_changed,
        select_category,
        fetch_products,
        fetch_careers,
        fetch_categories,
    }
}

export function ProductsDispatch() {
    return {
        select_category,
        something_changed,
        fetch_categories,
        userSignIn
    }
}

export function ProductsFormDispatch() {
    return {
        something_changed,
        select_category,
        fetch_products,
        fetch_categories,
    }
}

export function NewslettersDispatch() {
    return {
        select_category,
        something_changed,
        fetch_newsletters,
        userSignIn
    }
}

export function NewslettersFormDispatch() {
    return {
        something_changed,
        select_category,
        fetch_newsletters,
    }
}
