import {
    AUTH_RESTAURANT_JOINED,
    HIDE_MESSAGE,
    INIT_URL,
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    SELECT_RESTAURANT,
    SHOW_MESSAGE,
    SIGNIN_FACEBOOK_USER_SUCCESS,
    SIGNIN_GITHUB_USER_SUCCESS,
    SIGNIN_GOOGLE_USER_SUCCESS,
    SIGNIN_TWITTER_USER_SUCCESS,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER_SUCCESS,
    SIGNUP_USER_SUCCESS
} from "constants/ActionTypes";
import {consoleLog, validatingJSON} from "../constants/Helper";
import {
    ADDED_SOMETHING,
    FETCH_NEWSLETTERS,
    FETCH_CATEGORIES,
    FETCH_PRODUCTS,
    FETCH_RESTAURANTS, FETCH_SETTINGS, FETCH_USERS,
    SELECT_CATEGORY,
    SELECT_PRODUCT, FETCH_CAREERS
} from "../constants/ActionTypes";


const getFromStorage = (key) => {
    const selectedKey = localStorage.getItem(key);
    return validatingJSON(selectedKey) && JSON.parse(selectedKey) ? JSON.parse(selectedKey) : null;
}
const authUserState = getFromStorage("authUser");
const usersListState = getFromStorage("users");
const categoriesListState = getFromStorage("categories_list");
const productsListState = getFromStorage("products_list");
const settingsListState = getFromStorage("settings");
const newsLettersListState = getFromStorage("newsletters_list");
const careersListState = getFromStorage("careers_list");

const accessToken = localStorage.getItem("accessToken");

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    initURL: '',
    authUser: authUserState,
    accessToken: accessToken,
    selectedCategory: null,
    selectedProduct: null,
    categories_list: categoriesListState,
    products_list: productsListState,
    newsletters_list: newsLettersListState,
    careers_list: careersListState,
    loading:false,
    somethingChanged:null,
    users:usersListState,
    configuration:settingsListState

};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SIGNUP_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case SIGNIN_USER_SUCCESS: {
            localStorage.setItem('authUser', JSON.stringify(action.payload.data));
            localStorage.setItem('accessToken', action.payload.access_token);

            return {
                ...state,
                loader: false,
                authUser: action.payload.data,
                accessToken: action.payload.access_token
            }
        }
        case INIT_URL: {
            return {
                ...state,
                initURL: action.payload
            }
        }
        case SIGNOUT_USER_SUCCESS: {

            localStorage.removeItem("authUser");
            localStorage.removeItem("accessToken");
            localStorage.removeItem("settings");
            localStorage.removeItem("products_list");
            localStorage.removeItem("categories_list");
            localStorage.removeItem("newsletters_list");
            localStorage.removeItem("users");
            return {
                ...state,
                authUser: null,
                initURL: '/',
                loader: false,
                accessToken: null,

            }
        }

        case SHOW_MESSAGE: {
            return {
                ...state,
                alertMessage: action.payload,
                showMessage: true,
                loader: false
            }
        }
        case HIDE_MESSAGE: {
            return {
                ...state,
                alertMessage: '',
                showMessage: false,
                loader: false
            }
        }

        case SIGNIN_GOOGLE_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case SIGNIN_FACEBOOK_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case SIGNIN_TWITTER_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case SIGNIN_GITHUB_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case ON_SHOW_LOADER: {
            return {
                ...state,
                loader: true
            }
        }
        case ON_HIDE_LOADER: {
            return {
                ...state,
                loader: false
            }
        }


        case FETCH_USERS: {
            let payload = action.payload;
            localStorage.setItem("users", JSON.stringify(payload));

            return {
                ...state,
                loader: false,
                users: payload

            }
        }
        case FETCH_CATEGORIES: {

            let payload = action.payload.data;
            localStorage.setItem("categories_list", JSON.stringify(payload));
            return {
                ...state,
                loader: false,
                categories_list: payload

            }
        }
        case FETCH_SETTINGS: {

            let payload = action.payload.data;
            localStorage.setItem("settings", JSON.stringify(payload));
            return {
                ...state,
                loader: false,
                settings: payload

            }
        }
        case FETCH_PRODUCTS: {
            let payload = action.payload.data;
            localStorage.setItem("products_list", JSON.stringify(payload));

            return {
                ...state,
                loader: false,
                products_list: payload

            }
        }
        case FETCH_NEWSLETTERS: {
            let payload = action.payload.data;
            localStorage.setItem("newsletters_list", JSON.stringify(payload));

            return {
                ...state,
                loader: false,
                newsletters_list: payload

            }
        }
        case FETCH_CAREERS: {
            let payload = action.payload.data;
            localStorage.setItem("careers_list", JSON.stringify(payload));

            return {
                ...state,
                loader: false,
                careers_list: payload
            }
        }
        case SELECT_CATEGORY: {
            return {
                ...state,
                loader: false,
                selectedCategory: action.payload
            }
        }
        case SELECT_PRODUCT: {
            return {
                ...state,
                loader: false,
                selectedProduct: action.payload
            }
        }
        case ADDED_SOMETHING: {
            return {
                ...state,
                somethingChanged: new Date().getTime()

            }
        }
        default:
            return state;
    }
}
