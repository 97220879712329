import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import Auth from './Auth';
// import Restaurants from './Restaurant';
import Settings from './Settings';


export default (history) => combineReducers({
  router: connectRouter(history),
  auth: Auth,
  settings: Settings,

});
