import ApiURL from "./ApiURL";
import axios from "axios";
import React from "react";
import configureStore from '../store';


export function getSubDomain($default = null) {
    var full = window.location.host
//window.location.host is subdomain.domain.com
    var parts = full.split('.');
    var sub = parts[0];
    var domain = parts[1];
    var type = parts[2];
//sub is 'subdomain', 'domain', type is 'com'
    return !$default ? sub : $default;
}


export const isOnline = () => {
    return process.env.NODE_ENV === "production";
}
const state = () => {
    return configureStore().getState();
};


const config = (api, fun) => {

    let accesToken = localStorage.getItem("accessToken");


    // console.log(selectedRestaurant);
    // subdomain = authUser;

    const _config = {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            'Access-Control-Allow-Credentials': true,
            'content-type': 'multipart/form-data',
            "Authorization": "Bearer " + accesToken
        },
    }

    return _config;
}


export function addNewProduct(data) {
    const api = ApiURL.addProduct;
    return axios.post(api, data, config(api, "addNewProduct"));
}

export function getAllProducts() {
    const api = ApiURL.getProducts;
    return axios.get(api, config(api, "getAllProducts"));
}

export function deleteProduct(product_id) {

    const api = ApiURL.deleteProduct + "/" + product_id;

    return axios.delete(api, config(api, "deleteProduct"))

}


export function addNewNewsLetter(data) {
    const api = ApiURL.addNewsLetter;
    return axios.post(api, data, config(api, "addNewNewsLetter"));
}

export function getAllNewsLetters() {
    const api = ApiURL.getNewsLetters;
    return axios.get(api, config(api, "getAllNewsLetters"));
}

export function deleteNewsLetter(newsletter_id) {

    const api = ApiURL.deleteNewsLetter + "/" + newsletter_id;
    return axios.delete(api, config(api, "deleteNewsLetter"))

}

export function addNewCareer(data) {
    const api = ApiURL.addCareer;
    return axios.post(api, data, config(api, "addNewCareer"));
}

export function getAllCareers() {
    const api = ApiURL.getCareers;
    return axios.get(api, config(api, "getAllCareers"));
}

export function deleteCareer(career_id) {

    const api = ApiURL.deleteCareer + "/" + career_id;
    return axios.delete(api, config(api, "deleteCareer"))

}


export async function getRestaurant(restaurant_id) {
    let response = null;

    const api = ApiURL.getRestaurant + restaurant_id;
    await axios.get(api).then(res => {

        response = res.data;
    })
        .catch(error => {
            response = error;
        })


    return response;
}

export function addNewRestaurant(data) {
    const api = ApiURL.addRestaurant;
    return axios.post(api, data, {
        headers: {
            ...config(api, "addNewRestaurant").headers,
            'content-type': 'multipart/form-data'
        }
    });
}

export function deleteRestaurant(restaurant_id, key = "deactivate") {
    const api = ApiURL.deactivateRestaurant + `/${restaurant_id}/${key}`;
    return axios.delete(api, config(api, "deleteRestaurant"));
}

export function deactivateRestaurant(restaurant_id) {
    const api = ApiURL.deactivateRestaurant + "/" + restaurant_id;
    return axios.delete(api, config(api, "deactivateRestaurant"));
}

export function getAllRestaurants() {
    const api = ApiURL.allRestaurants;
    return axios.get(api, config(api, "getAllRestaurants"));
}


export function getAllCategories() {
    const api = ApiURL.getCategories;
    return axios.get(api, config(api, "getCategories"));
}

export function addNewCategory(formData) {

    const api = ApiURL.addCategory;
    return axios.post(api, formData, config(api, "addNewCategory"));
}

export function deleteCategory(category_id) {
    const api = ApiURL.deleteCategory;
    return axios.delete(api + "/" + category_id, config(api, "deleteCategory"));
}

export function getAllUsers() {
    const api = ApiURL.getUsers;
    return axios.get(api, config(api, "getAllUsers"));
}


export function deleteUser(user_id) {
    const api = ApiURL.deleteUser;
    return axios.delete(api + "/" + user_id, config(api, "deleteUser"));
}


export function getAllCountries() {
    const api = ApiURL.getCountries;
    return axios.get(api, config(api, "getAllCountries"));
}

export function getAllCities(country_id) {
    const api = ApiURL.getCities + "/" + country_id;
    return axios.get(api, config(api, "getAllCities"));
}

export function getAllLanguages() {
    const api = ApiURL.getLanguages;
    return axios.get(api, config(api, "getAllLanguages"));
}


export const getUserLocation = (setLat, setLng) => {
    const location = window.navigator && window.navigator.geolocation

    if (location) {
        location.getCurrentPosition((position) => {
            setLat(position.coords.latitude);
            setLng(position.coords.longitude);
        }, (error) => {
            console.log({latitude: 'err-latitude', longitude: 'err-longitude'})
        })
    }

}


export async function registerUser(formData, type = "post") {
    const api = type === "post" ? ApiURL.register : ApiURL.userUpdate;
    return axios.post(api, formData, config());

}


export async function login(username = getSubDomain(), password) {
    const api = ApiURL.login;

    let formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);


    return await axios.post(api, formData, config(api, "login"))


}


export function getSettingsInfo() {
    const api = ApiURL.getSettingsInfo;

    return axios.get(api, config(api, "getSettingsInfo"));


}

export function validatingJSON(json) {
    var checkedjson
    try {
        checkedjson = JSON.parse(json)
    } catch (e) {
    }
    return checkedjson
}

export function consoleLog(body, functionName) {

    console.log(functionName);
    console.log(body);
}

export function ucfirst(name) {
    return name.charAt(0).toUpperCase() + name.slice(1);
}


export function filterCategories(categoriesList, authUser) {
    return categoriesList.filter((cat) => {
        // if (authUser.type != "senior") {
        //     if (authUser.agentExist) {
        //         if (authUser.type == "admin") {
        //             return !cat.isOffer;
        //         } else {
        //             return cat.isOffer;
        //         }
        //     }
        // }

        return true;
    })
}


export function checkParams(v, params, def = "") {
    let results = v;


    for (let x = 0; x < params.length; x++) {
        const key = params[x];

        if (results && typeof results == "object" && results.hasOwnProperty(key)) {

            results = results[key]

        }
    }


    return typeof results == 'string' || typeof results == 'number' ? results : def;
}
