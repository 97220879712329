import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import {connect, useDispatch} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import {getSettingsInfo, getSubDomain, isOnline} from "../constants/Helper";
import {SignInStates} from "../reducers/connection/states";
import {SignInDispatch} from "../reducers/connection/functions";

const SignIn = ({authRestaurantJoined, showMessage, loader, alertMessage, userSignIn, showAuthLoader, hideMessage, authUser, history}) => {

    const dispatch = useDispatch();

    const [username, setUsername] = useState(getSubDomain(isOnline() ? null : "Fouadoo"));
    const [password, setPassword] = useState(isOnline() ? null : "Fouad2019@@");

    // username: 'fouadalbadin',
    // password: 'fouad2019@'
    // username: 'Fouadoo',
    // password: '123456789'


    useEffect(() => {
        if (showMessage) {
            setTimeout(() => {
                dispatch(hideMessage());
            }, 100);
        }
        if (authUser !== null) {
            history.push('/');
        }

    }, [showMessage, authUser]);

    return (
        <div
            className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
            <div className="app-login-main-content">

                <div className="app-logo-content d-flex align-items-center justify-content-center"
                     style={{backgroundColor: "#fff"}}>
                    <Link className="logo-lg" to="/" title="Jambo">
                        <img
                            width={"100%"}
                            src={require("../assets/images/logo.png")}
                            alt={"Karwanchi Group"}
                            title={"Karwanchi Group"}
                        />
                    </Link>
                </div>

                <div className="app-login-content">
                    <div className="app-login-header mb-4">

                        <h1>Welcome to Karwanchi Group</h1>
                    </div>

                    <div className="app-login-form">
                        <form>
                            <fieldset>
                                <TextField
                                    label={<IntlMessages id="appModule.username"/>}
                                    fullWidth
                                    onChange={(event) => setUsername(event.target.value)}
                                    defaultValue={username}
                                    margin="normal"
                                    className="mt-1 my-sm-3"
                                />
                                <TextField
                                    type="password"
                                    label={<IntlMessages id="appModule.password"/>}
                                    fullWidth
                                    onChange={(event) => setPassword(event.target.value)}
                                    defaultValue={password}
                                    margin="normal"
                                    className="mt-1 my-sm-3"
                                />

                                <div className="mb-3 d-flex align-items-center justify-content-between">
                                    <Button onClick={() => {
                                        dispatch(showAuthLoader());
                                        dispatch(userSignIn({username, password}));
                                    }} variant="contained" color="primary">
                                        <IntlMessages id="appModule.signIn"/>
                                    </Button>
                                </div>


                            </fieldset>
                        </form>
                    </div>
                </div>

            </div>
            {
                loader &&
                <div className="loader-view">
                    <CircularProgress/>
                </div>
            }
            {showMessage && NotificationManager.error(alertMessage)}
            <NotificationContainer/>
        </div>
    );
}


export default connect(SignInStates, SignInDispatch)(SignIn);
