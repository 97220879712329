import React from "react";
import {Area, AreaChart, ResponsiveContainer} from "recharts";
import ChartCard from "./ChartCard";
import ContainerHeader from "components/ContainerHeader/index";

import {increamentData} from "./mdata";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {fetch_categories, fetch_products, select_product, something_changed} from "../../../actions/Company";
import {checkParams} from "../../../constants/Helper";


const Dashboard = ({match, configuration, authUser, history, newsletters_list}) => {

    const isSenior = authUser.type === "senior";
    const isAdmin = authUser.type === "admin";

    const settings = {
        languages:0,
        statistics:{
            products:checkParams(configuration,['statistics','products'],0),
            careers:checkParams(configuration,['statistics','careers'],0),
            categories:checkParams(configuration,['statistics','categories'],0),
            newsletters:checkParams(configuration,['statistics','newsletters'],0),
            users:checkParams(configuration,['statistics','users'],0),
        }
    }




    return (
        <div className="app-wrapper">
            <div className="dashboard animated slideInUpTiny animation-duration-3">

                <ContainerHeader match={match} title={"Dashboard"}/>


                <div className="row">
                    {/*؛Products*/}
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                        <ChartCard
                            history={history} route={"/app/products/view"}
                            chartProperties={{
                                title: "Products",
                                prize: settings.statistics.products,
                                icon: 'stats',
                                bgColor: 'indigo',
                                styleName: 'up',
                                desc: '',
                                percent: '',
                            }}
                            children={<ResponsiveContainer width="100%" height={75}>
                                <AreaChart data={increamentData}
                                           margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                                    <Area dataKey='pv' strokeWidth={0} stackId="2" stroke='#273894' fill="#273894"
                                          fillOpacity={1}/>
                                </AreaChart>
                            </ResponsiveContainer>}
                        />

                    </div>

                    {/*Categories*/}
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                        <ChartCard
                            history={history} route={"/app/categories/view"}
                            chartProperties={{
                                title: 'Categories',
                                prize: settings.statistics.categories,
                                icon: 'stats',
                                bgColor: 'pink accent-2',
                                styleName: 'up',
                                desc: '',
                                percent: '',
                            }}
                            children={<ResponsiveContainer width="100%" height={75}>
                                <AreaChart data={increamentData}
                                           margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                                    <Area dataKey='pv' type='monotone' strokeWidth={0} stackId="2" stroke='#da2361'
                                          fill='#da2361'
                                          fillOpacity={1}/>
                                </AreaChart>
                            </ResponsiveContainer>}
                        />
                    </div>

                    {/*Newsletters*/}
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                        <ChartCard
                            history={history} route={"/app/newsletters/view"}
                            chartProperties={{
                                title: 'News',
                                prize: settings.statistics.newsletters,
                                icon: 'stats',
                                bgColor: 'pink accent-2',
                                styleName: 'up',
                                desc: '',
                                percent: '',
                            }}
                            children={<ResponsiveContainer width="100%" height={75}>
                                <AreaChart data={increamentData}
                                           margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                                    <Area dataKey='pv' type='monotone' strokeWidth={0} stackId="2" stroke='#da2361'
                                          fill='#da2361'
                                          fillOpacity={1}/>
                                </AreaChart>
                            </ResponsiveContainer>}
                        />
                    </div>

                    {/*؛Careers*/}
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                        <ChartCard
                            history={history} route={"/app/careers/view"}
                            chartProperties={{
                                title: "Careers",
                                prize: settings.statistics.careers,
                                icon: 'stats',
                                bgColor: 'indigo',
                                styleName: 'up',
                                desc: '',
                                percent: '',
                            }}
                            children={<ResponsiveContainer width="100%" height={75}>
                                <AreaChart data={increamentData}
                                           margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                                    <Area dataKey='pv' strokeWidth={0} stackId="2" stroke='#273894' fill="#273894"
                                          fillOpacity={1}/>
                                </AreaChart>
                            </ResponsiveContainer>}
                        />

                    </div>
                </div>
            </div>
        </div>
    );

}


const mapStateToProps = ({auth}) => {

    const {configuration, categories_list, selectedProduct, newsletters_list} = auth;
    const {loader, alertMessage, showMessage, authUser} = auth;
    return {
        loader,
        alertMessage,
        showMessage,
        authUser,
        configuration,
        categories_list,
        newsletters_list,
        selectedProduct
    }
};

export default withRouter(connect(mapStateToProps, {
    something_changed, select_product, fetch_products,
    fetch_categories
})(Dashboard));
