import React, {useEffect, useState} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {connect, useDispatch} from 'react-redux';
import Header from 'components/Header/index';
import Sidebar from 'containers/SideNav/index';
import Footer from 'components/Footer';
import Dashboard from './routes/dashboard';
import Users from './routes/users';
import Categories from './routes/categories';
import Products from './routes/products';
import Newsletters from './routes/newsletters';
import Careers from './routes/careers';
import Tour from '../components/Tour/index';
import {
    ABOVE_THE_HEADER,
    BELOW_THE_HEADER,
    COLLAPSED_DRAWER,
    FIXED_DRAWER,
    HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import {isIOS, isMobile} from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import TopNav from 'components/TopNav';
import {
    getAllNewsLetters,
    getAllCategories,
    getAllProducts,
    getSettingsInfo,
    getAllCareers,
    getAllUsers
} from "../constants/Helper";
import {AppStates} from "../reducers/connection/states";
import {AppDispatch} from "../reducers/connection/functions";
import {
    fetch_careers,
    fetch_newsletters,
    fetch_settings,
    fetch_categories,
    fetch_products,
    fetch_users
} from "../actions/Company";

const App = ({somethingChanged, userSignOut, ...props}) => {

    const dispatch = useDispatch();

    useEffect(() => {
        fetchAll("all");
    }, []);


    useEffect(() => {
        fetchAll("all");
    }, [somethingChanged]);


    const fetchAll = (fetch = "all") => {

        (fetch === "all" || fetch === "users") && getAllUsers().then(users => {
            if (users.data.error) {
                userSignOut();
                return;
            }
            dispatch(fetch_users(users));
        });

        (fetch === "all" || fetch === "settings") && getSettingsInfo().then(settings => {
            if (settings.data.error) {
                userSignOut();
                return;
            }
            dispatch(fetch_settings(settings));
        });

        (fetch === "all" || fetch === "products") && getAllProducts().then(products => {
            if (products.data.error) {
                userSignOut();
                return;
            }
            dispatch(fetch_products(products));
        });
        (fetch === "all" || fetch === "category") && getAllCategories().then(categories => {
            if (categories.data.error) {
                userSignOut();
                return;
            }

            dispatch(fetch_categories(categories));

        });
        (fetch === "all" || fetch === "newsletter") && getAllNewsLetters().then(newsletters => {
            if (newsletters.data.error) {
                userSignOut();
                return;
            }
            dispatch(fetch_newsletters(newsletters));
        });
        (fetch === "all" || fetch === "career") && getAllCareers().then(careers => {
            if (careers.data.error) {
                userSignOut();
                return;
            }
            dispatch(fetch_careers(careers));
        });
    };

    const {match, drawerType, navigationStyle, horizontalNavPosition, authUser} = props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
        document.body.classList.add('ios-mobile-view-height')
    } else if (document.body.classList.contains('ios-mobile-view-height')) {
        document.body.classList.remove('ios-mobile-view-height')
    }


    return (
        <div className={`app-container ${drawerStyle}`}>
            <Tour/>

            <Sidebar/>
            <div className="app-main-container">
                <div
                    className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
                    {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
                    <TopNav styleName="app-top-header"/>}
                    <Header/>
                    {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
                    <TopNav/>}
                </div>

                <main className="app-main-content-wrapper">
                    <div className="app-main-content">
                        <Switch>
                            <Route path={`${match.url}/dashboard`} component={Dashboard}/>

                            {authUser.type == "senior" && <Route path={`${match.url}/users`} component={Users}/>}

                            <Route path={`${match.url}/categories`} component={Categories}/>
                            <Route path={`${match.url}/products`} component={Products}/>
                            <Route path={`${match.url}/newsletters`} component={Newsletters}/>
                            <Route path={`${match.url}/users`} component={Users}/>
                            <Route path={`${match.url}/careers`} component={Careers}/>
                            {/*<Route path={`${match.url}/extra-pages`} component={ExtraPages}/>*/}

                            <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
                        </Switch>
                    </div>
                    <Footer/>
                </main>
            </div>
            {/*<ColorOption/>*/}
        </div>
    );
}


export default withRouter(connect(AppStates, AppDispatch)(App));
