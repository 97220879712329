export function UsersStates({settings, auth}) {

    const {drawerType, navigationStyle, horizontalNavPosition, locale} = settings;
    const {configuration, authUser, categories_list, loading, users} = auth;
    return {
        drawerType,
        navigationStyle,
        horizontalNavPosition,
        loading,
        configuration,
        categories_list,
        authUser,

        locale,
        users
    }
}


export function UsersFormStates({settings, auth}) {

    const {drawerType, navigationStyle, horizontalNavPosition, locale} = settings;
    const {configuration, authUser, categories_list, loading, users} = auth;
    return {
        drawerType,
        navigationStyle,
        horizontalNavPosition,
        loading,
        configuration,
        categories_list,
        authUser,

        locale,
        users
    }
}

export function CareersStates({settings, auth}) {

    const {drawerType, navigationStyle, horizontalNavPosition, locale} = settings;
    const {configuration, authUser, categories_list, careers_list, loading} = auth;
    return {
        drawerType,
        navigationStyle,
        horizontalNavPosition,
        loading,
        configuration,
        categories_list,
        careers_list,
        authUser,

        locale
    }
}


export function CareersFormStates({settings, auth}) {

    const {drawerType, navigationStyle, horizontalNavPosition, locale} = settings;
    const {configuration, authUser, categories_list, careers_list, loading} = auth;
    return {
        drawerType,
        navigationStyle,
        horizontalNavPosition,
        loading,
        configuration,
        categories_list,
        careers_list,
        authUser,

        locale
    }
}


export function CategoriesStates({settings, auth}) {

    const {drawerType, navigationStyle, horizontalNavPosition, locale} = settings;
    const {configuration, authUser, categories_list, loading} = auth;
    return {
        drawerType,
        navigationStyle,
        horizontalNavPosition,
        loading,
        configuration,
        categories_list,
        authUser,

        locale
    }
}


export function CategoriesFormStates({settings, auth}) {

    const {drawerType, navigationStyle, horizontalNavPosition, locale} = settings;
    const {configuration, authUser, categories_list, loading} = auth;
    return {
        drawerType,
        navigationStyle,
        horizontalNavPosition,
        loading,
        configuration,
        categories_list,
        authUser,

        locale
    }
}


export function SignInStates({auth}) {

    const {loader, alertMessage, showMessage, authUser, restaurant_joined} = auth;
    return {loader, alertMessage, showMessage, authUser, restaurant_joined}
}

export function AppStates({auth, settings}) {
    const {drawerType, navigationStyle, horizontalNavPosition} = settings;
    const {authUser, restaurant, configuration, somethingChanged} = auth;

    return {
        drawerType,
        navigationStyle,
        horizontalNavPosition,
        authUser,
        restaurant,
        somethingChanged,
        configuration
    }
}

export function ProductsStates({settings, auth}) {

    const {drawerType, navigationStyle, horizontalNavPosition, locale} = settings;
    const {configuration, authUser, products_list, categories_list, loading} = auth;
    return {
        drawerType,
        navigationStyle,
        horizontalNavPosition,
        loading,
        configuration,
        categories_list,
        authUser,

        products_list,
        locale
    }
}

export function ProductsFormStates({settings, auth}) {

    const {drawerType, navigationStyle, horizontalNavPosition, locale} = settings;
    const {configuration, authUser, categories_list, products_list, loading} = auth;
    return {
        drawerType,
        navigationStyle,
        horizontalNavPosition,
        loading,
        configuration,
        categories_list,
        products_list,
        authUser,

        locale
    }
}

export function NewslettersStates({settings, auth}) {

    const {drawerType, navigationStyle, horizontalNavPosition, locale} = settings;
    const {configuration, authUser, products_list, newsletters_list, categories_list, loading} = auth;
    return {
        drawerType,
        navigationStyle,
        horizontalNavPosition,
        loading,
        configuration,
        categories_list,
        authUser,
        newsletters_list,

        products_list,
        locale
    }
}

export function NewsletterFormStates({settings, auth}) {

    const {drawerType, navigationStyle, horizontalNavPosition, locale} = settings;
    const {configuration, authUser, newsletters_list, categories_list, products_list, loading} = auth;
    return {
        drawerType,
        navigationStyle,
        horizontalNavPosition,
        loading,
        configuration,
        categories_list,
        products_list,
        newsletters_list,
        authUser,

        locale
    }
}
